<template>
  <h1>Oops, it looks like the page you're looking for doesn't exist.</h1>
</template>

<script>
export default {
  name: 'NotFound',
  props: {
  }
}
</script>

<style>
</style>
